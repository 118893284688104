import React from 'react'
import Lightbox from '../../Lightbox/index'
import Map from '../../Map/MiniMap'
import LinkIcon from '../../../lib/LinkIcon'
// import PortableText from '../../serializers/portableText'

import styles from './art.module.css'

export default function Art ({title, artist, year, artCategories, location, geopoint, images, digitalPuzzle, largeImages}) {
  // console.log({largeImages})
  return (
    <>
      <div>
        <div className={styles.artWrapper}>
          <div className={styles.detailsWrapper}>
            {artist && (<p className={styles.artist}><span className={styles.by}>Artist:</span> {artist}</p>)}
            {year && (<p className={styles.year}><span className={styles.by}>Installation Year:</span> {year}</p>)}

            {location && (<p className={styles.location}><span className={styles.by}>Location:</span> {location}</p>)}

            {digitalPuzzle && (<div className={styles.linkWrapper}><LinkIcon type='puzzle' />  <a className={styles.link} href={digitalPuzzle} target='_blank' rel='noopener noreferrer'>Digital Puzzle</a></div>)}

          </div>
          {images && (
            <div className={styles.imagesWrapper}>
              <Lightbox images={images} largeImages={largeImages} />
            </div>)}
        </div>

        {geopoint && (<>
          <div className={styles.linkWrapper}><LinkIcon type='map' /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${geopoint.lat},${geopoint.lng}`} target='_blank' rel='noopener noreferrer' className={styles.link}>Directions</a></div>
        </>)}

        <br />
        {geopoint && (
          <Map title={title}
            images={images}
            artist={artist}
            year={year}
            artCategories={artCategories}
            location={location}
            geopoint={geopoint} />
        )}
      </div>
    </>
  )
}
