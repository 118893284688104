import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import ArtPage from '../components/page/ArtPage/index'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityArt(slug: { current: { eq: $slug } }) {
      title
      artist
      year
      artCategories {
        _id
        title
      }
      location
      digitalPuzzle
      geopoint {
        lat
        lng
      }
      slug {
        current
      }
      mainImage {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      images {
        _key
        alt
        asset {
          url
          fluid(maxWidth: 390) {
            ...GatsbySanityImageFluid
          }
        }
      }
      largeImages: images {
        _key
        alt
        asset {
          url
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    sanitySiteSettings {
      mainImageArt {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const PagePage = ({data: {sanityArt: page, sanitySiteSettings: site}}) => (
  <Layout mainImage={page.mainImage && page.mainImage.asset ? page.mainImage : site.mainImageArt} title={page.title}>
    {page.seoSettings && page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <ArtPage
      title={page.title}
      images={page.images}
      largeImages={page.largeImages}
      artist={page.artist}
      year={page.year}
      artCategories={page.artCategories}
      location={page.location}
      geopoint={page.geopoint}
      digitalPuzzle={page.digitalPuzzle}
    />
  </Layout>
)

export default PagePage
