import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import LinkIcon from '../../../lib/LinkIcon'
// import Map from '../../Map/MiniMap'

import Art from './Art'
// import Lightbox from '../../Lightbox/index'

import Container from '../../container'
// import SideBar from '../../SideBar'
import Footer from '../../Footer'
import styles from '../page.module.css'

const Page = ({title, images, artist, year, artCategories, location, geopoint, digitalPuzzle, largeImages}) => {
  const data = useStaticQuery(graphql`
    {
    sanitySideBar(id: {eq: "d72d47ce-3f50-5660-8eb4-a15f88e4752e"}) {
      title
      links {
        _key
        title
        siteLink
      }
    }
  }
  `)

  return (

    <>

      <article className={styles.root}>

        <Container>

          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>

            <p className={styles.tag}>{artCategories.title}</p>

          </div>
          <div className={styles.linkWrapper2}><LinkIcon type='left' />
            <Link to='/outdoor-art#main' className={styles.backLink}>Back to Results</Link>
          </div>

          <div>
            <div className={styles.mainContent}>

              <div className={styles.innerMainContent}>
                <Art title={title} artist={artist} year={year} artCategories={artCategories} location={location} geopoint={geopoint} images={images} digitalPuzzle={digitalPuzzle} largeImages={largeImages} />

              </div>

            </div>
          </div>

        </Container>

      </article>

      <Footer topBorder />

    </>
  )
}
export default Page
