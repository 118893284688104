import React, {useState} from 'react'
import ReactMapGL, {Popup, NavigationControl, FullscreenControl, ScaleControl} from 'react-map-gl'

import Pins from './Pin'
import PopUpInfo from './PopUp'

const fullscreenControlStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
}

const navStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px'
}

const scaleControlStyle = {
  position: 'absolute',
  bottom: 36,
  left: 0,
  padding: '10px'
}

export default function MiniMap ({title, images, artist, year, artCategories, location, geopoint}) {
  const artData = {title, images, artist, year, artCategories, location, geopoint}
  // console.log({artData})
  const TOKEN = 'pk.eyJ1IjoidmFyaWFudHN0dWRpb3N0ZWFtIiwiYSI6ImNrYWVsa3N5cTA0YjMycWxkMWs5dW14aHUifQ.Lu3czqOSejBpm29byrOXhA'
  const [viewport, setViewport] = useState({
    latitude: geopoint ? geopoint.lat : 40.423835,
    longitude: geopoint ? geopoint.lng : -104.691200,
    zoom: 16,
    bearing: 0,
    pitch: 0
  })

  const [popupInfo, setPopupInfo] = useState(artData)

  const onClickMarker = data => {
    setPopupInfo(data)
  }

  const renderPopup = () => {
    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor='top'
          longitude={popupInfo.geopoint.lng}
          latitude={popupInfo.geopoint.lat}
          closeOnClick={false}
          onClose={() => setPopupInfo(null)}
        >
          <PopUpInfo data={popupInfo} />
        </Popup>
      )
    )
  }

  return (
    <>
      <ReactMapGL
        {...viewport}
        width='100%'
        height='500px'
        mapStyle='mapbox://styles/mapbox/light-v10'
        onViewportChange={setViewport}
        mapboxApiAccessToken={TOKEN}
        scrollZoom={false}
      >
        <div style={fullscreenControlStyle}>
          <FullscreenControl />
        </div>
        <div style={navStyle}>
          <NavigationControl />
        </div>
        <div style={scaleControlStyle}>
          <ScaleControl />
        </div>
        <Pins data={artData} onClick={onClickMarker} />
        {renderPopup()}
      </ReactMapGL>
    </>
  )
}
